import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubProjectService {
  private SUB_PROJECT_URL = '/project/subprojects/#';
  private licenseRegister = '/license/request/#';
  private licenseHistory = '/license/history';
  private excelUpload = '/project/subprojects/#';
  private CRITERIA_URL = '/criteria/#'
  private APPRAISALFIELD_URL = '/appraisalfield/#';
  private UPLOAD_URL = '/projectdocs/';
  private UPLOAD_DOCS = '/projectdocs/:project_code';
  private APPRAISAL_URL = '/appraisal/#';
  private UPDATE_CRITERIA_URL = '/criteria/#';
  private PROJECT_REPORT = '/impact/#';
  private PROJECT_REJECT_REPORT = '/impact/exportRejectedImpacts/#';
  private PROJECT_BY_ID = '/project/#';
  private downloadTemplate = '/project/exportSubProjectSheetTemplate';
  private downloadExcel = '/project/exportSubProjectsExcel/#';
  private downloadReportUrl = '/jangrihasitevisit/getsitevisitreportpdf/#';
  private previewReportUrl = '/jangrihasitevisit/getsitevisitreportpdf/#/preview';
  private approvalReportUrl = '/jangrihasitevisit/getsitevisitreportpdf/#/APPROVAL';
  private downloadAllReportUrl = '/project/downloadcertificateszip/#';
  private feeReminder = '/project/feereminder/#';
  private OverAllReportDownload = '/impact/exportOveralltImpact/#';
  private downloadCertificate = '/impact/certificate/#';
  private downloadPhotographs = '/projectdocs/downloadprojectdocs/#';

  constructor(private http: HttpClient) { }

  // Make a HEAD request to get headers
  getFileType(url: string): Observable<HttpResponse<any>> {
    return this.http.head(url, { observe: 'response' });
  }

  getSubProjectLists(parentProjectCode: string, params: any) {
    return this.http.get(this.SUB_PROJECT_URL.replace('#', parentProjectCode), {
      params: params,
    });
  }

  getFeeReminder(projectCode: string) {
    return this.http.get(this.feeReminder.replace('#', projectCode));
  }

  uploadDocuments(projectCode: string, floor_plan: any, electricity_bills: any, water_bills: any, purchased_orders: any, building_photographs: any, count: number): Observable<any> {
    const url = `${this.UPLOAD_DOCS.replace(':project_code', projectCode)}?floor_plan=${floor_plan}&electricity_bills=${electricity_bills}&water_bills=${water_bills}&purchased_orders=${purchased_orders}&building_photographs=${building_photographs}&count=${count}`;
    let body = {};
    return this.http.post(url, body);
  }

  public updateAppraisalComplianceStatus(
    status: boolean,
    projectId: string,
    appraisal_code: string
  ) {
    const body = [
      { is_compliance_attached: status, appraisal_code: appraisal_code },
    ];
    return this.http.patch(this.APPRAISAL_URL.replace('#', projectId), body);
  }

  uploadFileToUrl(file: File, uploadUrl: string): Observable<any> {
    if (!file || !uploadUrl) {
      return throwError('Invalid file or upload URL');
    }

    const headers = new HttpHeaders({
      'Content-Type': file.type
    });

    return this.http.put(uploadUrl, file, { headers });
  }

  deleteDocs(projectId: string, file_name: any) {
    const url = `${this.UPLOAD_URL}${projectId}/${file_name}`;
    return this.http.delete(url);
  }

  getUploadDocs(projectCode: any) {
    const url = this.UPLOAD_DOCS.replace(':project_code', projectCode);
    return this.http.get(url);
  }

  getSingleSubProject(subProjectCode: string) {
    return this.http.get(this.SUB_PROJECT_URL.replace('#', subProjectCode));
  }

  updateAppraisalAwardedPoints(points: any, project_id: string, appraisal_code: string) {
    const body = [{
      achieved_points: points,
      appraisal_code: appraisal_code
    }]
    return this.http.patch(this.APPRAISAL_URL.replace('#', project_id), body)
  }

  public updateCriteriaStatus(project_id: string, body: any) {
    return this.http.patch(this.UPDATE_CRITERIA_URL.replace('#', project_id), body);
  }

  public getLicense(projectCode: any, body: any) {
    return this.http.post(this.licenseRegister.replace('#', projectCode), body);
  }

  public getLicenseHistory(project_code: any) {
    return this.http.get(`${this.licenseHistory}?project_code=${project_code}`);
  }

  public excelFileUpload(projectCode: any, body: FormData) {
    const url = this.excelUpload.replace('#', projectCode);
    return this.http.post(url, body);
  }

  public getCriteriaDetails(projectId: string) {
    return this.http.get(this.CRITERIA_URL.replace('#', projectId));
  }

  onSaveAppraisal(projectId: string, data: object) {
    // console.log(projectId,data);
    return this.http.patch(this.APPRAISALFIELD_URL.replace("#", projectId), data)
  }

  getUploadUrlWithFilename(project_id: string, comp_field_code: string, params: any) {
    let body = '';
    return this.http.post(this.UPLOAD_URL + project_id + '/' + comp_field_code, body, { params: params });
  }

  setAttemptedAppraisalData(attempt: boolean, code: string, project_id: string, points: number, status: string) {
    const body = [{
      attempted: attempt,
      appraisal_code: code,
      attempted_points: points,
      status: status
    }];
    return this.http.patch(this.APPRAISAL_URL.replace('#', project_id), body)
  }

  getProjectReport(subProjectCode: string) {
    return this.http.get(this.PROJECT_REPORT.replace('#', subProjectCode));
  }

  downloadZipFile(subProjectCode: any) {
    return this.http.get(this.downloadPhotographs.replace('#', subProjectCode))
  }

  // downloadZipFile(subProjectCode: any) {
  //   const url = this.downloadPhotographs.replace('#', subProjectCode);
  //   return this.http.get(url, { responseType: 'blob' });
  // } 

  getCertificate(subProjectCode: any) {
    return this.http.get(this.downloadCertificate.replace('#', subProjectCode));
  }

  getProjectRejectReport(subProjectCode: string) {
    return this.http.get(this.PROJECT_REJECT_REPORT.replace('#', subProjectCode));
  }

  getOverAllProjectReport(projectCode: string) {
    return this.http.get(this.OverAllReportDownload.replace('#', projectCode));
  }

  public downloadTemplateFile(): Observable<any> {
    const url = `${this.downloadTemplate}`;
    return this.http.get(url);
  }

  public downloadProjectExcel(parentProjectCode: any): Observable<any> {
    return this.http.get(this.downloadExcel.replace('#', parentProjectCode))
    // return this.http.get(url);
  }

  public updateProject(project_code: string, body: any) {
    return this.http.patch(this.PROJECT_BY_ID.replace('#', project_code), body)
  }

  public downloadReport(project_id: string) {
    return this.http.get(this.downloadReportUrl.replace('#', project_id));
  }

  public previewReport(project_id: string) {
    return this.http.get(this.previewReportUrl.replace('#', project_id));
  }

  public approvalReport(project_id: string) {
    return this.http.get(this.approvalReportUrl.replace('#', project_id));
  }

  public downloadAllReports(parent_project_code: string) {
    return this.http.get(this.downloadAllReportUrl.replace('#', parent_project_code));
  }

}
